

<div class="container">
    <div class="row">
        <div class="col-md-10 offset-md-1 shadow-lg px-md-5 py-3 border bg-white">
    
    
    
    <p><strong>PRIVACY AND PAYMENT POLICY</strong></p>
    <p><strong>Last Updated:</strong> October 10<sup>th</sup>, 2024</p>
    <p>This Privacy and Payment Policy explains how our Company ("we", "our", "us") collects, uses, processes, and shares your personal and financial information. By using our services, you agree to this policy.</p>
    <ol>
    <li><strong> INFORMATION WE COLLECT</strong></li>
    </ol>
    <p><strong>1.1 Personal Information</strong><br /> We collect the following information to provide and improve our services:</p>
    <ul>
    <li><strong>Name</strong></li>
    <li><strong>Email address</strong></li>
    <li><strong>Phone number</strong></li>
    <li><strong>Location of SIM card receipt</strong></li>
    </ul>
    <p><strong>1.2 Financial Information</strong><br /> We collect financial transaction data via our third-party payment processor to complete your purchases. We do not store any payment details, including credit/debit card information.</p>
    <ol start="2">
    <li><strong> PURPOSE OF DATA COLLECTION</strong></li>
    </ol>
    <p><strong>2.1 Personal Information</strong><br /> We use the personal information you provide for the following purposes:</p>
    <ul>
    <li>Activating your SIM card</li>
    <li>Ensuring regulatory compliance with the Department of Telecom (India)</li>
    <li>Providing customer support for SIM-related queries</li>
    </ul>
    <p><strong>2.2 Financial Information</strong><br /> We collect financial data to:</p>
    <ul>
    <li>Facilitate SIM card purchases</li>
    <li>Generate and provide you with invoices</li>
    <li>Process refunds or payment disputes, when applicable</li>
    </ul>
    <ol start="3">
    <li><strong> DATA PROCESSING AND STORAGE</strong></li>
    </ol>
    <ul>
    <li><strong>Personal Data:</strong> Collected information is stored securely in India and retained for two (2) years in compliance with the Department of Telecom's rules.</li>
    <li><strong>Financial Data:</strong> Payments are securely processed by <strong>CCAvenue</strong>, which complies with industry standards such as <strong>PCI-DSS</strong>. We do not store payment information on our servers.</li>
    </ul>
    <ol start="4">
    <li><strong> SHARING OF INFORMATION</strong></li>
    </ol>
    <p>We do not share, sell, or disclose your information to third parties except:</p>
    <ul>
    <li><strong>Government Agencies:</strong> When required by the Department of Telecom (India) or other legal entities.</li>
    <li><strong>Payment Processing:</strong> We use <strong>CCAvenue</strong> to process financial transactions securely.</li>
    </ul>
    <ol start="5">
    <li><strong> PAYMENT PROCESSING</strong></li>
    </ol>
    <p>We use <strong>CCAvenue</strong>, a secure, third-party payment processor, for all financial transactions. Your payment information is handled securely, and we do not store any credit or debit card details on our servers. CCAvenue ensures compliance with <strong>PCI-DSS</strong> standards.</p>
    <ol start="6">
    <li><strong> USER RIGHTS AND CONSENT</strong></li>
    </ol>
    <p><strong>6.1 Consent</strong><br /> By providing personal and financial information, you consent to our collection, use, and processing as outlined in this policy.</p>
    <p><strong>6.2 Rights</strong><br /> You have the right to:</p>
    <ul>
    <li><strong>Access your data</strong>: Request a copy of the personal data we have collected.</li>
    <li><strong>Correct your data</strong>: Request corrections to inaccurate or incomplete information.</li>
    <li><strong>Delete your data</strong>: Request the deletion of your personal data, subject to legal requirements.</li>
    <li><strong>Withdraw consent</strong>: You may withdraw your consent at any time, which may affect our ability to provide services.</li>
    </ul>
    <ol start="7">
    <li><strong> DATA SECURITY</strong></li>
    </ol>
    <p>We take appropriate security measures to protect your personal information from unauthorized access, disclosure, or modification. We use encryption and secure methods for data storage and transmission. However, no method of data transmission over the internet is fully secure, and we cannot guarantee absolute security.</p>
    <ol start="8">
    <li><strong> REFUNDS AND CHARGEBACKS</strong></li>
    </ol>
    <p>Refunds and chargebacks will be handled in accordance with our [Refund Policy]. For payment disputes, please contact us at [Contact Information].</p>
    <ol start="9">
    <li><strong> DISCLAIMER</strong></li>
    </ol>
    <p>While we take all reasonable steps to safeguard your data, no system is completely secure. We cannot guarantee the security of information transmitted over the internet, and you do so at your own risk.</p>
    <ol start="10">
    <li><strong> CONTACT US</strong></li>
    </ol>
    <p>For any questions about this policy or your rights regarding your data, please contact us at:</p>
    <ul>
    <li><strong>Email:</strong> info@koodosimindia.com</li>
    <li><strong>Phone:</strong> +91 9004048000</li>
    <li><strong>Mailing Address:</strong> 602 Midas Chambers, Andheri West &ndash; Mumbai, 400053</li>
    </ul>
    <ol start="11">
    <li><strong> CHANGES TO THIS POLICY</strong></li>
    </ol>
    <p>We may update this Privacy and Payment Policy periodically. When we make changes, we will notify you via email or through a notice on our website. The "Last Updated" date at the top of this page reflects the latest revision.</p>
    <p>&nbsp;</p>
    
    </div>
    </div>
    </div>