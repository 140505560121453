import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { PlanLineItem, SIMPlanDetail } from '../Models/simplan-detail';
import { SIMRegistration } from '../Models/simregistration';
import { SIMRegistrationFinal } from '../Models/simregistration-final';
import { SIMRegistrationSec } from '../Models/simregistration-sec';
import {
  SIMRegistrationResponse,
  UserFiles,
  FileUploadModel,
} from '../Models/SIMRegistrationResponse';
import { City } from '../Models/City';
import { Expectedperiod } from '../Models/Expectedperiod';

import { SIMValidateResponse } from '../Models/SIMValidateResponse';
import { SIMAndPaymentResponse } from '../Models/SIMAndPaymentResponse';
import { giftvoucherRequest } from '../Models/giftrequest';
@Injectable({
  providedIn: 'root',
})
export class ApplicationServiceService {
  public BaseUrl: string = 'https://www.canadasimregister.com/APIWEBSERVICE/';
  //public BaseUrl: string = "http://localhost:61908/";
  EmailServiceURL: string = 'https://www.koodosimindia.com/KoodoEmailService/';
  // _GiftVoucherServiceurl: string = 'https://localhost:7098/';
  _GiftVoucherServiceurl: string =
    'https://www.koodosimindia.com/KoodoVoucherServiceapi/';
  constructor(private http: HttpClient) {}

  GetBaseAPIURL() {
    return 'https://www.canadasimregister.com/APIWEBSERVICE/';
  }

  //
  // Add SIM Registration First Page
  AddSIMRegistrationFirstPage(
    SIMRegistration: SIMRegistration
  ): Observable<SIMRegistrationResponse> {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    let URL = this.BaseUrl + 'api/Registration/Add/koodoPostpaid';
    return this.http.post<SIMRegistrationResponse>(URL, SIMRegistration, {
      headers: headers,
    });
  }

  // Add SIM Registration First Page
  AddSIMRegistrationSec(
    SIMRegistrationSec: SIMRegistrationSec,
    RegistrationID: string
  ): Observable<SIMRegistrationResponse> {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    let URL = this.BaseUrl + 'api/Registration/UpdatekoodoPostpaid';
    return this.http.post<SIMRegistrationResponse>(URL, SIMRegistrationSec, {
      headers: headers,
    });
  }

  // Add SIM Registration First Page
  AddSIMRegistrationFinal(
    SIMRegistrationFinal: SIMRegistrationFinal,
    RegistrationID: string
  ): Observable<SIMRegistrationResponse> {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    let URL = this.BaseUrl + 'api/Registration/Add/Final';
    return this.http.post<SIMRegistrationResponse>(URL, SIMRegistrationFinal, {
      headers: headers,
    });
  }

  //   Get SIM Plan
  GetSIMPlan(SIMNumber: string): Observable<SIMPlanDetail> {
    const headers = { 'content-type': 'application/json' };
    let URL = this.BaseUrl + 'api/SIMPlan/SIMPlan?SIMPlan=' + SIMNumber;
    return this.http.get<SIMPlanDetail>(URL);
  }

  // Get SIM Registration Details from SIM Registration ID

  GetSIMRegistrationDetail(
    RegistrationID: string
  ): Observable<SIMRegistrationResponse> {
    const headers = { 'content-type': 'application/json' };
    let URL = this.BaseUrl + 'api/Registration/Get/' + RegistrationID;
    return this.http.get<SIMRegistrationResponse>(URL);
  }

  //   Get SIM Plan
  ValidateSIMCard(SIMNumber: string): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    let URL =
      this.BaseUrl + 'api/SIMPlan/SIMPlan/Validate?SINICCD=' + SIMNumber;
    return this.http.get<any>(URL);
  }

  //   Get City List
  CityList(): Observable<City[]> {
    const headers = { 'content-type': 'application/json' };
    let URL = this.BaseUrl + 'api/SIMPlan/City';
    return this.http.get<City[]>(URL);
  }
  //
  //   Get GetExpectedperiods List
  GetExpectedperiods(): Observable<Expectedperiod[]> {
    const headers = { 'content-type': 'application/json' };
    let URL = this.BaseUrl + 'api/UserService/Expectedperiods';
    return this.http.get<Expectedperiod[]>(URL);
  }

  //  Get NEW SIM Validation Response
  SIMCardValidationNew(SIMNumber: string): Observable<SIMValidateResponse> {
    const headers = { 'content-type': 'application/json' };
    let URL =
      this.BaseUrl + 'api/SIMPlan/SIMPlan/ValidateNEW?SINICCD=' + SIMNumber;
    return this.http.get<SIMValidateResponse>(URL);
  }

  //  Get NEW SIM Validation Response
  SIMCardValidationByNetwork(
    SIMNumber: string,
    SIMNetwork: string
  ): Observable<SIMValidateResponse> {
    const headers = { 'content-type': 'application/json' };
    let URL =
      this.BaseUrl +
      `api/SIMPlan/SIMPlan/ValidatebySource?SINICCD=${SIMNumber}&NetworkName=${SIMNetwork}`;
    return this.http.get<SIMValidateResponse>(URL);
  }

  //  Get NEW SIM Validation Response
  SIMCardNumberANDPayments(SIMNo: string): Observable<SIMAndPaymentResponse> {
    const headers = { 'content-type': 'application/json' };
    let URL = this.BaseUrl + `api/SIMPlan/SIMAndPayment?SIMNo=${SIMNo}`;
    return this.http.get<SIMAndPaymentResponse>(URL);
  }

  PostFile(FileUploadModel: FileUploadModel): Observable<any> {
    const endpoint = this.BaseUrl + 'api/upload/file';
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    const formData: FormData = new FormData();
    formData.append(
      'File',
      FileUploadModel.fileToUpload,
      FileUploadModel.fileToUpload.name
    );
    formData.append('SIMRegistrationID', FileUploadModel.id);
    formData.append('RegistrationGUID', FileUploadModel.SIMRegistrationID);
    formData.append('uploadtype', FileUploadModel.uploadtype);
    formData.append('UserID', FileUploadModel.userID);
    return this.http.post<any>(endpoint, formData);
  }

  VoucherPostFile(FileUploadModel: FileUploadModel): Observable<any> {
    const endpoint =
      this._GiftVoucherServiceurl + 'api/FileuploadService/UploadFile';
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    const formData: FormData = new FormData();
    formData.append(
      'File',
      FileUploadModel.fileToUpload,
      FileUploadModel.fileToUpload.name
    );
    formData.append('Id', FileUploadModel.id);
    formData.append('FileName', FileUploadModel.fileToUpload.name);
    formData.append('FileBob', null);
    formData.append('UploadedFile', FileUploadModel.fileToUpload);
    formData.append('PersonID', FileUploadModel.SIMRegistrationID);
    formData.append('Email', FileUploadModel.userID);
    formData.append('IContactNumber', FileUploadModel.uploadtype);
    return this.http.post<any>(endpoint, formData);
  }

  VoucherRequest(giftvoucherRequest: giftvoucherRequest): Observable<any> {
    const endpoint =
      this._GiftVoucherServiceurl + 'api/voucherrequestservice/Add';
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    return this.http.post<any>(endpoint, giftvoucherRequest);
  }

  EmailNotificationOrderConfirmation(
    Registrationid: any,
    Source: string,
    SaleID: any
  ): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const URL =
      this.EmailServiceURL +
      `api/EmailTemplate/SendEmailNotificationOrderConfirmed/${SaleID}/${Source}?RegisterID=${Registrationid}`;
    return this.http.post<any>(URL, { headers });
  }

  // Get Koodo Plan
  GetKoodoplanList(): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const NewURL = this.BaseUrl + `api/KoodoService/GetPlans?networkName=Koodo-Postpaid`;
    return this.http.get<any>(NewURL);
  }

  // Get Koodo Plan
  GetKoodoplanByName(PlanName: string): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const NewURL = this.BaseUrl + `api/KoodoService/GetPlan?name=${PlanName}`;
    return this.http.get<any>(NewURL);
  }
}
